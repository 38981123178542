<template>
    <editable-profile-section
        v-if="userAllowedToEdit || hasSkills"
        :fields="['skills']"
        :title="$t('skills')"
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <skill-grid
                :value="user.skills"
                :placeholder="$t('profile_field_skills_empty')"
                :use-grid-layout="false"
            >
                <template #viewing="{skill}">
                    <div
                        v-tooltip.bottom="skill.value.length <= 20 ? '' : skill.value"
                        class="bold ellipsis"
                    >
                        {{ skill.value }}
                    </div>
                </template>
            </skill-grid>
        </template>
        <template #editing="{formData}">
            <skill-grid
                v-model="formData.skills"
                :add-button-text="$t('skill_add_text')"
                :use-grid-layout="false"
                edit-mode
            >
                <template #editing="{skill, setSkill, onBlur}">
                    <input
                        v-autofocus
                        :value="skill.value"
                        type="text"
                        class="skill-input"
                        :placeholder="$t('profile_field_skills_placeholder')"
                        :maxlength="skillNameMaxLength"
                        @input="setSkill({value: $event.target.value, proficiency: skill.proficiency})"
                        @blur="onBlur"
                    />
                </template>
                <template #viewing="{skill}">
                    <div
                        v-if="skill.value"
                        class="bold ellipsis"
                    >
                        {{ skill.value }}
                    </div>
                    <div
                        v-else
                        class="bold text-medium-contrast"
                    >
                        {{ $t("profile_field_skills_placeholder") }}
                    </div>
                </template>
            </skill-grid>
        </template>
    </editable-profile-section>
</template>

<script>
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";
import SkillGrid from "@/components/profile/SkillGrid";
import { getGlobalConfiguration } from "@/global-config";

export default {
    name: "SkillSection",
    components: {
        SkillGrid,
        EditableProfileSection,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        userAllowedToEdit: Boolean,
        openInEditMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            skillNameMaxLength: getGlobalConfiguration().input_validation_user_profile_skill_name_max_length,
        };
    },
    computed: {
        hasSkills() {
            if (this.user.skills) return this.user.skills.length > 0;
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.skill-input {
    border: none;
    padding: 0;
    border-radius: 0;
}
</style>
