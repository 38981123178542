<template>
    <div
        class="select-group"
        @click="$event.stopPropagation()"
    >
        <button
            class="select-trigger"
            @click="toggleSelect"
        >
            <span :class="{grey: !selectValue}">
                {{ selectValue ? selectedDisplayNameProvider(selectValue) : placeholder }}
            </span>
            <icon
                v-if="hasFocus"
                class="icon"
                color="--highest-contrast"
                name="chevron-up"
                size="small"
            />
            <icon
                v-else
                class="icon"
                color="--highest-contrast"
                name="chevron-down"
                size="small"
            />
        </button>
        <select
            ref="select"
            :value="!selectValue ? undefined : keyProvider(selectValue)"
            :class="{focus: hasFocus, 'depth-1': hasFocus}"
            :size="size"
            class="select"
            @blur="onBlur"
            @focus="hasFocus = true"
            @keypress.enter="onSelect($event.target.value)"
            @keyup.esc="toggleSelect"
        >
            <option
                v-for="option in options"
                :key="keyProvider(option)"
                :value="keyProvider(option)"
                @click="onSelect($event.target.value)"
            >
                {{ displayNameProvider(option) }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "EverestSelect",
    props: {
        /**
         * The selected option.
         * @model
         */
        value: Object,
        /** Selectable options. Should be of same type as `value` */
        options: { type: Array, default: () => [] },
        /** Function used to access the key of the selected value/option */
        keyProvider: {
            type: Function,
            default: (value) => value.key,
        },
        /** Function used to access the display name of the options */
        displayNameProvider: {
            type: Function,
            default: (value) => value.value,
        },
        /**
         * Function used to access the display name of the selected value. If not set the `displayNameProvider` is used.
         */
        selectedDisplayNameProvider: {
            type: Function,
            default() {
                return this.displayNameProvider;
            },
        },
        /** Placeholder text to show when the value is empty */
        placeholder: String,
        /** If true options will be opened when component is mounted */
        initOpened: Boolean,
    },
    data() {
        const selectValue = this.value;
        return {
            /** value of the select field */
            selectValue: selectValue,
            hasFocus: false,
            /** previous value of the select field */
            oldSelectValue: selectValue,
        };
    },
    computed: {
        size() {
            return this.hasFocus ? 5 : 1;
        },
    },
    mounted() {
        if (this.initOpened) {
            this.toggleSelect();
        }
    },
    methods: {
        onSelect(selected) {
            this.selectValue = this.options.find(option => this.keyProvider(option) === selected);
            this.oldSelectValue = this.selectValue;
            this.hasFocus = false;
            this.$refs.select.blur();
            /**
             * when the value changed
             * @property {Object} value: the selected value
             */
            this.$emit("input", this.selectValue);
        },
        onBlur(event) {
            this.hasFocus = false;
            this.selectValue = this.oldSelectValue;
            /** after the select lost its focus */
            this.$emit("blur", event);
        },
        toggleSelect() {
            if (this.hasFocus) {
                this.hasFocus = false;
                this.$refs.select.blur();
            } else {
                this.hasFocus = true;
                this.$nextTick(() => {
                    const select = this.$refs.select;
                    const selectedOption = select.selectedOptions[0];
                    if (selectedOption) {
                        selectedOption.scrollIntoView();
                    }
                    select.focus();
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
select,
option {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.select-trigger {
    width: 100%;
    background: var(--background);
    padding: 0.5rem;
    border: 1px solid var(--high-contrast);
    border-radius: var(--border-radius-small);
    text-align: start;
    z-index: 2;
    position: absolute;

    .grey {
        color: var(--medium-contrast);
    }
}

.select-group {
    position: relative;
    width: 100%;
    min-height: calc(1rem + 18px);

    .select {
        width: 100%;
        cursor: pointer;
        border-radius: var(--border-radius-small);
        border-color: var(--medium-contrast);

        &.focus {
            left: 0;
            bottom: 0;
            position: absolute;
            z-index: 1;
            border-color: var(--low-contrast);
            padding: unset;
            width: fit-content;
            transform: translateY(100%);
        }

        option {
            height: 2.8rem;
            padding: 0.8rem;
            border-bottom: 1px solid var(--lowest-contrast);

            &:hover {
                background-color: var(--lowest-contrast);
            }

            /* https://www.py4u.net/discuss/973522
             * IMPORTANT: background, background-color, background-image do not override the nasty Chrome blue
             * Even though the styling is displayed as overridden in dev tools, only inset box-shadow actually overrides!
             */
            &:focus,
            &:active,
            &:checked {
                box-shadow: 0 0 10px 100px var(--low-contrast) inset;
            }
        }
    }
}

.icon {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.6rem;
}
</style>

<docs>
```vue
<template>
    <div>
        <div>
            <everest-select
                v-model="value"
                :options="options"
                :key-provider="val => val.iso2"
                :display-name-provider="val => `${val.englishName} (${val.nativeName})`"
                :selected-display-name-provider="val => val.englishName"
                placeholder="Select language"
            />
        </div>
        <div>value is: {{ value }}</div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: undefined,
                options: [
                    { iso2: "aa", englishName: "Afar", nativeName: "Afar" },
                    { iso2: "ab", englishName: "Abkhazian", nativeName: "Аҧсуа" },
                    { iso2: "af", englishName: "Afrikaans", nativeName: "Afrikaans" },
                    { iso2: "ak", englishName: "Akan", nativeName: "Akana" },
                    { iso2: "am", englishName: "Amharic", nativeName: "አማርኛ" },
                    { iso2: "an", englishName: "Aragonese", nativeName: "Aragonés" },
                    { iso2: "ar", englishName: "Arabic", nativeName: "العربية" },
                    { iso2: "as", englishName: "Assamese", nativeName: "অসমীয়া" },
                    { iso2: "av", englishName: "Avar", nativeName: "Авар" },
                    { iso2: "ay", englishName: "Aymara", nativeName: "Aymar" },
                    { iso2: "az", englishName: "Azerbaijani", nativeName: "Azərbaycanca / آذربايجان" },
                    { iso2: "ba", englishName: "Bashkir", nativeName: "Башҡорт" },
                    { iso2: "be", englishName: "Belarusian", nativeName: "Беларуская" },
                    { iso2: "bg", englishName: "Bulgarian", nativeName: "Български" },
                    { iso2: "bh", englishName: "Bihari", nativeName: "भोजपुरी" },
                ],
            };
        },
    };
</script>
```
</docs>
