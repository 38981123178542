<template>
    <form-select
        :placeholder="placeholder"
        :value="value"
        :options="options"
        :disabled="disabled"
        label="name"
        clearable
        @input="$emit('input', $event)"
        @search="onSearch"
    />
</template>

<script>
import { mapGetters } from "vuex";
import { teamService } from "@web/services";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import FormSelect from "@web/components/forms/FormSelect";
import * as globalConfiguration from "@backend/common/global-config.json";

export default {
    name: "TeamSelect",
    components: {
        FormSelect,
    },
    props: {
        /**
         * @property {string} name
         * @property {string} uid
         */
        value: Object,
        placeholder: String,
        disabled: Boolean,
        maxAutocompleteEntries: { type: Number, default: globalConfiguration.teams_max_count_per_intranet },
    },
    data() {
        return {
            options: [],
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET_UID]),
    },
    mounted() {
        this.searchForValues();
    },
    methods: {
        async searchForValues(search) {
            const { hits } = await teamService.search.search({
                intranetUid: this.intranetUid,
                query: search,
                pageNumber: 0,
                pageSize: this.maxAutocompleteEntries,
            });
            this.options = hits.map(({ name, uid }) => ({ uid, name }));
        },
        async onSearch(search, done) {
            try {
                await this.searchForValues(search);
            } catch (e) {
                this.options = [];
            } finally {
                done();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
