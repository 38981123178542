<template>
    <div
        ref="cover"
        class="user-visual content"
        :style="coverStyling"
    >
        <profile-cover-image
            :user="user"
            @update="updateProfileCoverUrl"
        />
        <profile-image
            class="user-avatar"
            :user="user"
            @section-updated="$emit('section-updated', $event)"
        />
        <div class="name">
            <div class="name-label">
                {{ $t("user_profile_head_introduction") }}
            </div>
            <slot name="displayName">
                <h1 class="ellipsis-multiline">
                    {{ user.displayName }}
                </h1>
            </slot>
        </div>
        <div class="actions margin-small">
            <send-hey-five-button
                v-if="currentUser.uid !== user.uid"
                :recipient="user"
            />
        </div>
    </div>
</template>
<script>
import ProfileImage from "@/views/intranet/directory/ProfileDetail/ProfileImage";
import SendHeyFiveButton from "@/components/social-notifications/SendHeyFiveButton";
import ProfileCoverImage from "@/views/intranet/directory/ProfileDetail/ProfileCoverImage";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";

export default {
    name: "ProfileCoverAndAvatar",
    components: {
        ProfileImage,
        ProfileCoverImage,
        SendHeyFiveButton,
    },
    props: {
        user: Object,
    },
    data() {
        return {
            profileCoverUrl: this.user.profileCoverImagePath ? this.user.profileCoverImagePath : "/profile-bg.jpg",
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        coverStyling() {
            return {
                "background-image": `linear-gradient(transparent 50%, rgba(0,0,0,0.5) 100%), url(${this.profileCoverUrl})`,
            };
        },
    },
    methods: {
        updateProfileCoverUrl(url) {
            this.profileCoverUrl = url;
        },
    },
};
</script>
<style lang="scss" scoped>
.user-avatar {
    margin: 1.5rem;
    position: absolute;
}

.user-visual {
    background: var(--lowest-contrast, #{$off-white});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 300px;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 3.3/1;
    position: relative;
}

.content {
    border-radius: var(--border-radius);
    width: 100%;
    color: $white;
    display: flex;
    align-items: flex-end;

    .name {
        margin: 1.5rem 12.5rem 1.5rem calc(196px + 3.5rem); // left side: avatar-xxlarge width + margin
        flex: 1 1 auto;
        overflow: hidden;
        z-index: 1;

        * {
            margin-bottom: 0;
        }

        @media screen and (max-width: $breakpoint-profile-large) {
            margin: 1.5rem 5rem 1.5rem calc(196px + 3.5rem);
        }

        @media screen and (max-width: $breakpoint-profile-small) {
            margin: 1.5rem 5rem 1.5rem calc(128px + 2.5rem);
        }
    }

    .actions {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
