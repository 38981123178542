<template>
    <!--
    on selecting an option
    @event input
    @property {string} value - the selected value
    -->
    <v-select
        v-model="selectedValue"
        :loading="loading"
        :placeholder="placeholder"
        :options="options"
        :label="label"
        :filterable="!hasSearchListener"
        :clearable="clearable"
        :components="{Deselect}"
        :disabled="disabled"
        :class="{'text-select': !customOption, 'empty': isEmpty}"
        @input="$emit('input', $event)"
        @open="open = true"
        @close="open = false"
        @search:focus="updateSearchValue"
        @search:blur="updateSearchValue"
        @option:selected="search = $event"
        @search="onSearch"
    >
        <template #option="data">
            <!-- @slot the options within the dropdown list and the selected value -->
            <slot
                name="option"
                v-bind="data"
            ></slot>
        </template>
        <template #selected-option="data">
            <slot
                name="option"
                v-bind="data"
            ></slot>
        </template>
        <template #open-indicator="{ attributes }">
            <Icon
                v-bind="attributes"
                name="chevron-down"
            />
        </template>
        <template #spinner="{ loading }">
            <Spinner v-if="loading"/>
        </template>
        <template #search="{ attributes, events }">
            <input
                v-if="!customOption || open"
                v-model="search"
                class="vs__search"
                :placeholder="placeholder"
                v-bind="attributes"
                v-on="events"
            />
        </template>
    </v-select>
</template>

<script>
import vSelect from "vue-select";
import Spinner from "@web/components/Spinner";
import Deselect from "@web/components/forms/vue-select/Deselect";

/**
 * Select input based no Vue-Select.
 */
export default {
    name: "FormSelect",
    components: {
        Spinner,
        vSelect,
    },
    props: {
        /** The selected option
         * @model */
        value: [Object, String],
        /** Field to use as label when value and options are objects */
        label: String,
        /** Selectable options, exactly like in [vue-select](https://vue-select.org/api/props.html#options) */
        options: { type: Array, default: () => [] },
        /** When set, offers a clearing X */
        clearable: Boolean,
        /** When set, displays a spinner */
        loading: Boolean,
        /** Placeholder used for the search and selection */
        placeholder: String,
        /** when set, disables the input */
        disabled: Boolean,
    },
    data() {
        return {
            // Override for the X to clear within vue-select
            Deselect,
            open: false,
            search: this.getOptionLabel(this.value),
            prevSearch: "",
            selectedValue: this.value,
        };
    },
    computed: {
        customOption() {
            return "option" in this.$scopedSlots;
        },
        hasSearchListener() {
            return "search" in this.$listeners;
        },
        isEmpty() {
            return !this.selectedValue;
        },
        $clearButton() {
            return this.$el.querySelector(".vs__clear");
        },
    },
    watch: {
        selectedValue(selectedValue) {
            this.updateSearchValue();
            this.$emit("input", selectedValue);
        },
    },
    mounted() {
        this.$clearButton.addEventListener("click", this.clearValue);
    },
    destroyed() {
        this.$clearButton.removeEventListener("click", this.clearValue);
    },
    methods: {
        getOptionLabel(option) {
            // option could be null but this leads to exceptions because its type of object.
            return vSelect.props.getOptionLabel.default.call(this, option || undefined);
        },
        updateSearchValue() {
            this.search = this.getOptionLabel(this.selectedValue);
        },
        async onSearch(search, loading) {
            if (!this.hasSearchListener) return;
            const expectsNoMoreResults = (this.prevSearch !== "" && search.includes(this.prevSearch) && this.options.length === 0);
            if (expectsNoMoreResults) return;
            loading(true);
            const done = () => {
                this.prevSearch = search;
                loading(false);
            };
            /**
             * Triggered when typing to search for option values
             * @property {string} search - the search terms
             * @property {function} done - callback that **must** be called when async search is finished
             */
            this.$emit("search", search, done);
        },
        clearValue() {
            this.selectedValue = undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.text-select {
    ::v-deep {
        // the input keeps editable but this requires the obligatory selected label of vue-select to be invisible
        .vs__selected {
            display: none;
        }
    }

    // while selection is not empty
    &:not(.empty) {
        ::v-deep {
            // always show the clear button, which is usually hidden while typing in the search field
            .vs__clear {
                display: block !important;
            }
        }
    }
}
</style>

<docs>
### Simple optionlist
```vue
<form-select :options="['first', 'second', 'third']" class="mbottom-small"/>

Next to textfield:<br/>
<div class="flex">
    <div style="width: 200px">
        <form-select value="pneumonoultramicroscopicsilicovolcanoconiosis" :options="['pneumonoultramicroscopicsilicovolcanoconiosis']"/>
    </div>
    <div class="flex-fill">
        <input type="text"/>
    </div>
</div>
```

### Clearable
```vue
<form-select value="first" :options="['first', 'second', 'third']" clearable/>
```

### Placeholder
```vue
<form-select :options="['first', 'second', 'third']" placeholder="please select" clearable class="mbottom-small"/>

<form-select value="first" :options="['first', 'second', 'third']" placeholder="please select" clearable/>
```

### Loading
```vue
<form-select :options="['first', 'second', 'third']" loading/>
```

### Async options
A simple search for Github repositories.
```vue
<template>
    <div>
        <form-select :options="options" label="full_name" @search="onSearch"/>

        Always empty:
        <form-select :options="[]" label="full_name" @search="onSearch"/>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                options: [],
            };
        },
        methods: {
            async searchOptions(search = "") {
                if (search) {
                    const response = await window.fetch(`https://api.github.com/search/repositories?q=${escape(search)}`);
                    if (response.status !== 200) return;
                    const {items} = await response.json();
                    this.options = items.map(item => item.full_name);
                } else {
                    const response = await window.fetch(`https://api.github.com/repositories`);
                    if (response.status !== 200) return;
                    const items = await response.json();
                    this.options = items.map(item => item.full_name);
                }
            },
            async onSearch(search, done) {
                try {
                    await this.searchOptions(search);
                } catch (e) {
                    this.options = [];
                } finally {
                    done();
                }
            },
        },
        mounted() {
            this.searchOptions();
        },
    };
</script>
```

### Custom option template
A simple search for Github repositories with custom option templates.
```vue
<template>
    <div>
        <form-select v-model="value" :options="options" label="full_name" @search="onSearch">
            <template #option="item">
                <div class="flex">
                    <img :src="item.owner.avatar_url" width="25" height="25" class="mright-small"/>
                    {{item.full_name}}
                </div>
            </template>
        </form-select>
        <pre style="width: 100%; max-height: 10rem; overflow: auto"><code>
        {{value}}
        </code></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: undefined,
                options: [],
            };
        },
        methods: {
            async searchOptions(search = "") {
                if (search) {
                    const response = await window.fetch(`https://api.github.com/search/repositories?q=${escape(search)}`);
                    if (response.status !== 200) return;
                    const {items} = await response.json();
                    this.options = items;
                } else {
                    const response = await window.fetch(`https://api.github.com/repositories`);
                    if (response.status !== 200) return;
                    const items = await response.json();
                    this.options = items;
                }
            },
            async onSearch(search, done) {
                try {
                    await this.searchOptions(search);
                } catch (e) {
                    this.options = [];
                } finally {
                    done();
                }
            },
        },
        mounted() {
            this.searchOptions();
        },
    };
</script>
```
</docs>
