<template>
    <div>
        <skill-grid
            v-model="localValue"
            :tile-height="75"
            :edit-mode="editMode"
            :placeholder="$t('profile_field_language_empty')"
            :add-button-text="$t('languages_add_text')"
            @input="$emit('input', localValue)"
        >
            <template #editing="{skill, setSkill, onBlur}">
                <everest-select
                    :value="skill.value"
                    :options="filteredLanguages(skill.value)"
                    :key-provider="val => val.iso2"
                    :display-name-provider="val => `${val.englishName} (${val.nativeName})`"
                    :selected-display-name-provider="val => val.englishName"
                    :placeholder="$t('profile_field_language_placeholder')"
                    :init-opened="true"
                    class="language-select"
                    @input="setSkill({value: $event, proficiency: skill.proficiency})"
                    @blur="onBlur"
                />
            </template>
            <template #viewing="{skill}">
                <div class="bold">
                    <span :class="{ 'text-medium-contrast': !skill.value }">
                        {{ skill.value ? skill.value.englishName : $t("profile_field_language_placeholder") }}
                    </span>
                    <span
                        v-if="levelDescription(skill)"
                        class="label"
                    >
                        {{ levelDescription(skill) }}
                    </span>
                </div>
            </template>
        </skill-grid>
    </div>
</template>

<script>
import SkillGrid from "@/components/profile/SkillGrid";
import EverestSelect from "@/components/EverestSelect";

export default {
    name: "LanguageSkillGrid",
    components: {
        SkillGrid,
        EverestSelect,
    },
    props: {
        /**
         * The selected set of languages.
         * @type {LanguageSkill[]}
         * @model
         */
        value: { type: Array, default: () => [] },
        /** List of available languages to select from.
         *  @type {Iso2Language[]} */
        availableLanguages: { type: Array, default: () => [] },
        /** Tiles are editable when set. */
        editMode: { type: Boolean, default: false },
    },
    data() {
        return {
            // used to allow for passing through v-model into skill-grid
            localValue: this.value,
        };
    },
    methods: {
        levelDescription(skill) {
            if (skill.proficiency === 1) {
                return this.$t("languages_skill_proficiency_1");
            }
            if (skill.proficiency === 2) {
                return this.$t("languages_skill_proficiency_2");
            }
            if (skill.proficiency === 3) {
                return this.$t("languages_skill_proficiency_3");
            }
            if (skill.proficiency === 4) {
                return this.$t("languages_skill_proficiency_4");
            }
            return "";
        },
        filteredLanguages(selectedOption) {
            const onlyValues = this.localValue.map((language) => language.value.iso2);
            const selectedIso2 = selectedOption && selectedOption.iso2;
            return this.availableLanguages
                .filter((option) => selectedIso2 === option.iso2 || !onlyValues.includes(option.iso2));
        },
    }
};
</script>

<style lang="scss" scoped>
.language-select {
    width: 60%;
}

::v-deep {
    select:focus {
        position: absolute;
        z-index: 1;
        width: fit-content !important;
    }
}
</style>
