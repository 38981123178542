<template>
    <editable-profile-section
        tag="header"
        :fields="['displayName', 'jobTitle', 'location', 'team']"
        :title="$t('user_profile_head')"
        button-position="bottom-right"
        hide-title
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <ProfileHead
                :user="user"
                @section-updated="$emit('section-updated', $event)"
            />
        </template>
        <template #editing="{formData, isSaving}">
            <ProfileHead
                :user="user"
            >
                <template #displayName>
                    <input
                        v-model.trim="formData.displayName"
                        class="display-name-input"
                        type="text"
                        :maxlength="constraints.displayName.maxlength"
                        :disabled="isSaving"
                        :placeholder="$t('user_profile_field_placeholder_display_name')"
                    />
                </template>
                <template #position>
                    <job-title-input
                        v-model="formData.jobTitle"
                        class="profile-intro-field-input"
                        :disabled="isSaving"
                        :placeholder="$t('user_profile_field_placeholder_job_title')"
                    />
                </template>
                <template #team>
                    <team-select
                        v-if="intranet.teamCount > 0"
                        v-model="formData.team"
                        class="profile-intro-field-input"
                        :disabled="isSaving"
                        :placeholder="$tc('team', 1)"
                    />
                    <input
                        v-if="intranet.teamCount === 0"
                        type="text"
                        disabled
                        value="-"
                    />
                </template>
                <template #location>
                    <location-input
                        v-model="formData.location"
                        class="profile-intro-field-input"
                        :disabled="isSaving"
                        :placeholder="$t('user_profile_field_placeholder_location')"
                    />
                </template>
            </ProfileHead>
        </template>
    </editable-profile-section>
</template>

<script>
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";
import { getGlobalConfiguration } from "@/global-config";
import JobTitleInput from "@/views/intranet/directory/ProfileDetail/JobTitleInput";
import TeamSelect from "@/views/intranet/directory/ProfileDetail/TeamSelect";
import LocationInput from "@/views/intranet/directory/ProfileDetail/LocationInput";
import ProfileHead from "@/views/intranet/directory/ProfileDetail/ProfileHead";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";

export default {
    name: "HeaderSection",
    components: {
        EditableProfileSection,
        JobTitleInput,
        TeamSelect,
        LocationInput,
        ProfileHead,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        openInEditMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        constraints() {
            return {
                displayName: {
                    maxlength: getGlobalConfiguration().input_validation_user_display_name_max_length,
                },
            };
        },
    }
};
</script>

<style lang="scss" scoped>
.display-name-input {
    /* keep spacing of h1 in view mode */
    margin-top: 0.9rem;
    max-width: 20rem;
}

.profile-intro-field-input {
    margin-top: 0.2rem;
}
</style>
