<template>
    <hoverlay
        v-if="isCurrentUsersProfile"
        class="hoverlay"
        :force-overlay-state="imageUploadOptionsOpened || loading ? true : undefined"
    >
        <template #on-hover>
            <div class="center-container">
                <image-upload-button
                    :busy="loading"
                    :upload-image="uploadProfileCover"
                    :delete-image="user.profileCoverImageStoragePath ? confirmDeleteProfileCoverImage : undefined"
                    :cropper-aspect-ratio="4"
                    :cropper-rounded="false"
                    :text="$t('profile_cover_picture_edit')"
                    @open-upload-options="imageUploadOptionsOpened = true"
                    @close-upload-options="imageUploadOptionsOpened = false"
                />
            </div>
        </template>
    </hoverlay>
</template>

<script>
import Hoverlay from "@/components/Hoverlay";
import ImageUploadButton from "@/components/image-upload/ImageUploadButton";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import {
    profileAuxiliaryService,
    profileService
} from "@/services";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

export default {
    name: "ProfileCoverImage",
    components: { Hoverlay, ImageUploadButton },
    props: {
        user: Object,
    },
    data() {
        return {
            imageUploadOptionsOpened: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        isCurrentUsersProfile() {
            return this.currentUser.uid === this.user.uid;
        },
    },
    methods: {
        async uploadProfileCover(blob) {
            this.loading = true;
            try {
                await profileAuxiliaryService.updateProfileCoverImage(this.intranetUid, this.currentUser.uid, blob);
                analytics.log(
                    getGlobalConfiguration().analytics_event_name_profile_cover_picture_uploaded,
                    { imageSource: "cameraRoll" },
                );
                const { profileCoverImageStoragePath, profileCoverImagePath } = await profileService.getFullUserProfile(
                    this.intranetUid,
                    this.user.uid,
                );
                this.user.profileCoverImageStoragePath = profileCoverImageStoragePath;
                this.user.profileCoverImagePath = profileCoverImagePath;
                this.$emit("update", profileCoverImagePath);
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("profile_cover_picture_upload_failed"),
                });
            } finally {
                this.loading = false;
            }
        },
        confirmDeleteProfileCoverImage() {
            this.$modal.show("confirm-modal", {
                text: this.$t("profile_cover_picture_delete_confirm"),
                warning: true,
                callback: (confirmed) => {
                    if (confirmed) {
                        this.deleteProfileCoverImage();
                    }
                },
            });
        },
        async deleteProfileCoverImage() {
            this.loading = true;
            try {
                await profileService.updateUserProfile(this.intranetUid, this.user.uid, { profileCoverImageStoragePath: null });
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_cover_picture_deleted);
                this.user.profileCoverImageStoragePath = undefined;
                this.user.profileCoverImagePath = undefined;
                this.$emit("update", "/profile-bg.jpg");
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("profile_cover_picture_delete_failed"),
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.hoverlay::v-deep {
    position: absolute;
    .overlay {
        border-radius: var(--border-radius);
    }
}
</style>
