<template>
    <editable-profile-section
        :title="$t('info')"
        :fields="['birthday', 'hiringDate']"
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <table>
                <tr>
                    <td class="text-nowrap">
                        {{ $tc("birthday", 1) }}
                    </td>
                    <td>
                        <template v-if="user.birthday">
                            <strong>{{ $d(dateYMDToDate(user.birthday), "dateLong") }}</strong>
                            <span class="in-years">({{ dayjsFromDateYMD(user.birthday).locale("en").fromNow(true) }})</span>
                        </template>
                        <template v-if="!user.birthday">
                            -
                        </template>
                    </td>
                </tr>
                <tr>
                    <td class="text-nowrap">
                        {{ $t("started_on") }}
                    </td>
                    <td>
                        <template v-if="user.hiringDate">
                            <strong>{{ $d(dateYMDToDate(user.hiringDate), "dateLong") }}</strong>
                            <span class="in-years">({{ dayjsFromDateYMD(user.hiringDate).locale("en").fromNow() }})</span>
                        </template>
                        <template v-if="!user.hiringDate">
                            -
                        </template>
                    </td>
                </tr>
            </table>
        </template>
        <template #editing="{formData, isSaving}">
            <div class="form-group">
                <label for="birthday">{{ $tc("birthday", 1) }}</label>
                <date-input
                    id="birthday"
                    v-model="formData.birthday"
                    mode="date"
                    :disabled="isSaving"
                    :max-date="new Date()"
                    :min-date="hundredYearsAgo"
                />
            </div>
            <div class="form-group">
                <label for="hiringDate">{{ $t("started_on") }}</label>
                <date-input
                    id="hiringDate"
                    v-model="formData.hiringDate"
                    mode="date"
                    :disabled="isSaving"
                    :min-date="hundredYearsAgo"
                />
            </div>
        </template>
    </editable-profile-section>
</template>

<script>
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";
import {
    dateMinus,
    dateYMDToDate,
    dayjsFromDateYMD
} from "@/lib/time-utils";
import DateInput from "@/components/forms/DateInput";

export default {
    name: "InfoSection",
    components: {
        EditableProfileSection,
        DateInput,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        openInEditMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hundredYearsAgo() {
            return dateMinus({ years: -100 });
        },
    },
    methods: {
        dayjsFromDateYMD,
        dateYMDToDate,
    }
};
</script>

<style lang="scss" scoped>
.in-years {
    color: var(--high-contrast);
    margin-left: 1rem;
}
</style>
