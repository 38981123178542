<template>
    <editable-profile-section
        v-if="userAllowedToEdit || !isAboutMeEmpty"
        :title="$t('about_me')"
        :fields="['aboutMe']"
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <RichTextEditor
                v-if="user.aboutMe && !isAboutMeEmpty"
                :value="user.aboutMe"
                :is-empty.sync="isAboutMeEmpty"
                truncate
            />
            <p
                v-else
                class="text-medium-contrast"
            >
                {{ $t("profile_about_me_placeholder") }}
            </p>
        </template>
        <template #editing="{formData, isSaving}">
            <RichTextEditor
                v-model="formData.aboutMe"
                class="input"
                :is-empty.sync="isAboutMeEmpty"
                edit-mode
                :place-holder="$t('profile_about_me_placeholder')"
            />
        </template>
    </editable-profile-section>
</template>

<script>
import RichTextEditor from "@/components/editor/RichTextEditor";
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";

export default {
    name: "AboutMeSection",
    components: {
        EditableProfileSection,
        RichTextEditor
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        userAllowedToEdit: Boolean,
        openInEditMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isAboutMeEmpty: false,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>
