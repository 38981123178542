var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userAllowedToEdit || _vm.hasSkills)?_c('editable-profile-section',{attrs:{"fields":['skills'],"title":_vm.$t('skills'),"user":_vm.user,"editable":_vm.editable,"open-in-edit-mode":_vm.openInEditMode},on:{"update:editing":function($event){return _vm.$emit('update:editing', $event)},"section-updated":function($event){return _vm.$emit('section-updated', $event)}},scopedSlots:_vm._u([{key:"viewing",fn:function(){return [_c('skill-grid',{attrs:{"value":_vm.user.skills,"placeholder":_vm.$t('profile_field_skills_empty'),"use-grid-layout":false},scopedSlots:_vm._u([{key:"viewing",fn:function(ref){
var skill = ref.skill;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(skill.value.length <= 20 ? '' : skill.value),expression:"skill.value.length <= 20 ? '' : skill.value",modifiers:{"bottom":true}}],staticClass:"bold ellipsis"},[_vm._v(" "+_vm._s(skill.value)+" ")])]}}],null,false,853735047)})]},proxy:true},{key:"editing",fn:function(ref){
var formData = ref.formData;
return [_c('skill-grid',{attrs:{"add-button-text":_vm.$t('skill_add_text'),"use-grid-layout":false,"edit-mode":""},scopedSlots:_vm._u([{key:"editing",fn:function(ref){
var skill = ref.skill;
var setSkill = ref.setSkill;
var onBlur = ref.onBlur;
return [_c('input',{directives:[{name:"autofocus",rawName:"v-autofocus"}],staticClass:"skill-input",attrs:{"type":"text","placeholder":_vm.$t('profile_field_skills_placeholder'),"maxlength":_vm.skillNameMaxLength},domProps:{"value":skill.value},on:{"input":function($event){return setSkill({value: $event.target.value, proficiency: skill.proficiency})},"blur":onBlur}})]}},{key:"viewing",fn:function(ref){
var skill = ref.skill;
return [(skill.value)?_c('div',{staticClass:"bold ellipsis"},[_vm._v(" "+_vm._s(skill.value)+" ")]):_c('div',{staticClass:"bold text-medium-contrast"},[_vm._v(" "+_vm._s(_vm.$t("profile_field_skills_placeholder"))+" ")])]}}],null,true),model:{value:(formData.skills),callback:function ($$v) {_vm.$set(formData, "skills", $$v)},expression:"formData.skills"}})]}}],null,false,831768281)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }