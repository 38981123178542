<template>
    <ProfileDetail
        :key="userUid"
        class="ptop-medium pbottom-medium"
        :user-uid="userUid"
        :focus-section="focusSection"
    />
</template>

<script>
import ProfileDetail from "./ProfileDetail";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";

export default {
    name: "ProfileRoute",
    components: {
        ProfileDetail,
    },
    inheritAttrs: false,
    props: {
        userUid: String,
        /** @see ProfileLevel */
        focusSection: String,
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
    },
    methods: {
        logAnalyticsEvent() {
            if (!this.userUid) return;
            if (this.userUid === this.currentUser.uid) {
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_opened_own);
            } else {
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_opened);
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => vm.logAnalyticsEvent());
    },
};
</script>

<style scoped>
</style>
