<template>
    <div class="avatar avatar-xxlarge">
        <img
            v-if="!isCurrentUsersProfile"
            :src="user.photoURL"
            :alt="user.displayName"
        />
        <hoverlay
            v-else
            :force-overlay-state="imageUploadOptionsOpened || loading ? true : undefined"
        >
            <template #on-hover>
                <div class="center-container hoverlay">
                    <image-upload-button
                        :upload-image="uploadProfileImage"
                        :delete-image="user.profileImageStoragePath ? confirmDeleteProfileImage : undefined"
                        :busy="loading"
                        @open-upload-options="imageUploadOptionsOpened = true"
                        @close-upload-options="imageUploadOptionsOpened = false"
                    ></image-upload-button>
                </div>
            </template>
            <img
                :src="user.photoURL"
                :alt="user.displayName"
            />
        </hoverlay>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import ImageUploadButton from "@/components/image-upload/ImageUploadButton";
import Hoverlay from "@/components/Hoverlay";
import { PROFILE_MODULE_NAME } from "@/store/profile/profile";
import {
    DELETE_PROFILE_IMAGE,
    UPDATE_PROFILE_IMAGE
} from "@/store/profile/actions";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";
import { profileService } from "@/services";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";

export default {
    name: "ProfileImage",
    components: { ImageUploadButton, Hoverlay },
    props: {
        user: Object,
    },
    data: () => ({
        imageUploadOptionsOpened: false,
        loading: false,
    }),
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        isCurrentUsersProfile() {
            return this.currentUser.uid === this.user.uid;
        },
    },
    methods: {
        ...mapActions({
            _updateProfileImage: PROFILE_MODULE_NAME + UPDATE_PROFILE_IMAGE,
            _deleteProfileImage: PROFILE_MODULE_NAME + DELETE_PROFILE_IMAGE,
        }),
        async uploadProfileImage(blob) {
            this.loading = true;
            try {
                await this._updateProfileImage(blob);
                analytics.log(
                    getGlobalConfiguration().analytics_event_name_profile_picture_uploaded,
                    { imageSource: "cameraRoll" },
                );
                await this.updateProfileImageData();
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("profile_picture_upload_failed"),
                });
            } finally {
                this.loading = false;
            }
        },
        confirmDeleteProfileImage() {
            this.$modal.show("confirm-modal", {
                text: this.$t("profile_picture_delete_confirm"),
                warning: true,
                callback: (confirmed) => {
                    if (confirmed) {
                        this.deleteProfileImage();
                    }
                }
            });
        },
        async deleteProfileImage() {
            this.loading = true;
            try {
                await this._deleteProfileImage();
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_picture_deleted);
                await this.updateProfileImageData();
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("profile_picture_delete_failed"),
                });
            } finally {
                this.loading = false;
            }
        },
        async updateProfileImageData() {
            const { photoURL, profileImageStoragePath } = await profileService.getFullUserProfile(
                this.intranetUid,
                this.user.uid,
            );
            this.user.photoURL = photoURL;
            this.user.profileImageStoragePath = profileImageStoragePath;
            this.$emit("section-updated", {
                photoURL,
                profileImageStoragePath,
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    transform: translateY(50%);
    flex: 0 0 auto;
}

.hoverlay {
    --icon-size: 1.5rem;
}

@media screen and (max-width: $breakpoint-profile-large) {
    .avatar {
        transform: none;
    }
}

@media screen and (max-width: $breakpoint-profile-small) {
    .avatar {
        height: 128px;
        width: 128px;
        transform: none;
        margin-bottom: 1.5rem;
    }
}
</style>
