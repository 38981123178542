<template>
    <div
        v-if="googleScriptLoaded"
        class="location-field-input"
    >
        <vue-google-autocomplete
            id="map"
            ref="autocompleteInput"
            classname="input"
            :placeholder="placeholder"
            :types="null"
            :disabled="disabled || isError"
            @hook:mounted="onAutoCompleteMounted"
            @keyup="onKeyUp"
            @placechanged="onPlaceChanged"
        >
        </vue-google-autocomplete>
    </div>
    <div v-else>
        <!-- preserves space the input needs when script is injected -->
        <input
            type="text"
            disabled
        />
    </div>
</template>

<script>
import { firebaseConfig } from "@web/firebaseConfig";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const GOOGLE_MAPS_SCRIPT_ID = "googleapis-maps";
const GOOGLE_MAPS_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places&language=en`;

/**
 * text input that is backed by Google Places api
 */
export default {
    name: "LocationInput",
    components: { VueGoogleAutocomplete },
    props: {
        value: { type: Object, required: true },
        placeholder: String,
        disabled: Boolean,
    },
    data() {
        return {
            googleScriptLoaded: false,
            isError: false,
        };
    },
    mounted() {
        this.appendGoogleMapsScriptToHead();
    },
    methods: {
        async appendGoogleMapsScriptToHead() {
            if (document.getElementById(GOOGLE_MAPS_SCRIPT_ID)) {
                this.onGoogleMapsReady();
                return;
            }
            const newScript = document.createElement("script");
            newScript.id = GOOGLE_MAPS_SCRIPT_ID;
            // this is Googles weird way of handling errors with Maps Api auth. https://developers.google.com/maps/documentation/javascript/events#auth-errors
            // could cause problems when we have multiple inputs. ignoring that for know as we don't expect this kind of errors in production
            window.gm_authFailure = this.onError;
            newScript.onerror = this.onError;
            newScript.onload = this.onGoogleMapsReady;
            document.head.appendChild(newScript);
            newScript.src = GOOGLE_MAPS_SCRIPT_URL;
        },
        onGoogleMapsReady() {
            this.googleScriptLoaded = true;
        },
        onAutoCompleteMounted() {
            if (this.value && this.value.name) {
                this.$refs.autocompleteInput.update(this.value.name || "");
            }
        },
        onPlaceChanged(addressData, placeResultData, id) {
            this.$emit("input", {
                place: {
                    place_id: placeResultData.place_id,
                    formatted_address: placeResultData.formatted_address,
                },
                name: placeResultData.name,
            });
        },
        // handles user input which is not backed by google places selection
        onKeyUp(e) {
            const k = e.which;
            // handles user interaction with keyboard on auto-suggest list
            if (k === 13 || /* Enter */ (k >= 35 && k <= 40) /* Home, End, Arrow Keys */) {
                return;
            }
            this.$emit("input", { name: e.target.value });
        },
        onError(error) {
            this.$notify({
                group: "app",
                type: "error",
                text: "Google Maps Api is not available.",
                duration: 5000,
            });
            error && console.error(error);
            const scriptTag = document.getElementById(GOOGLE_MAPS_SCRIPT_ID);
            scriptTag && scriptTag.remove();
            this.isError = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.location-field-input {
    width: 100%;
}
</style>
<style lang="scss">
.location-field-input .gm-err-autocomplete {
    background-image: none !important;
}
</style>
