<template>
    <v-select
        :placeholder="placeholder"
        :options="options"
        taggable
        :filterable="false"
        :clearable="false"
        :components="{Deselect}"
        :disabled="disabled"
        @option:selected="inputValue = $event"
        @search="onSearch"
    >
        <template #option="data">
            <!-- @slot the options within the dropdown list and the selected value -->
            <slot
                name="option"
                v-bind="data"
            ></slot>
        </template>
        <template #selected-option="data">
            <slot
                name="option"
                v-bind="data"
            ></slot>
        </template>
        <template #spinner="{ loading }">
            <Spinner v-if="loading"/>
        </template>
        <template #search="{ attributes, events }">
            <input
                v-model="inputValue"
                :maxlength="maxLength"
                class="vs__search"
                :placeholder="placeholder"
                v-bind="attributes"
                v-on="events"
            />
        </template>
    </v-select>
</template>

<script>
import vSelect from "vue-select";
import Deselect from "@web/components/forms/vue-select/Deselect";

/**
 * An autocomplete input based on vue-select.
 */
export default {
    name: "AutocompleteInput",
    components: {
        vSelect,
    },
    props: {
        /** The input's value
         * @model */
        value: [Object, String],
        /** Selectable options, exactly like in [vue-select](https://vue-select.org/api/props.html#options) */
        options: { type: Array, default: () => [] },
        /** placeholder text when input is empty */
        placeholder: String,
        /** max length of the input's value */
        maxLength: Number,
        /** when set, disables the input */
        disabled: Boolean,
    },
    data() {
        return {
            // Override for the X to clear within vue-select
            Deselect,
            inputValue: this.value,
            prevSearch: "",
        };
    },
    computed: {
        $clearButton() {
            return this.$el.querySelector(".vs__clear");
        },
    },
    watch: {
        inputValue(inputValue) {
            this.$emit("input", inputValue);
        },
    },
    mounted() {
        this.$clearButton.addEventListener("click", this.clearValue);
    },
    destroyed() {
        this.$clearButton.removeEventListener("click", this.clearValue);
    },
    methods: {
        async onSearch(search, loading) {
            if (!this.$listeners.search) return;
            const expectsNoMoreResults = (this.prevSearch !== "" && search.includes(this.prevSearch) && this.options.length === 0);
            if (expectsNoMoreResults) return;
            loading(true);
            const done = () => {
                this.prevSearch = search;
                loading(false);
            };
            /**
             * Triggered when typing to search for option values
             * @property {string} search - the search terms
             * @property {function} done - callback that **must** be called when async search is finished
             */
            this.$emit("search", search, done);
        },
        clearValue() {
            this.inputValue = "";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    // the input keeps editable but this requires the obligatory selected label of vue-select to be invisible
    .vs__selected,
        // the chevron down
    .vs__open-indicator,
        // visible when there is no suggestion
    .vs__no-options {
        display: none;
    }
}
</style>

<docs>
Has the same slots like [FormSelect](#/Components/FormSelect).

```vue
<template>
    <div>
        <autocomplete-input :options="options" v-model="value" placeholder="please type" @search="onSearch"/>
        {{ value }}
    </div>
</template>
<script>
    export default {
        data() {
            return {
                options: [],
                value: undefined,
            };
        },
        methods: {
            async searchOptions(search = "") {
                if (search) {
                    const response = await window.fetch(`https://api.github.com/search/repositories?q=${escape(search)}`);
                    if (response.status !== 200) return;
                    const { items } = await response.json();
                    this.options = items.map(item => item.full_name);
                } else {
                    const response = await window.fetch(`https://api.github.com/repositories`);
                    if (response.status !== 200) return;
                    const items = await response.json();
                    this.options = items.map(item => item.full_name);
                }
            },
            async onSearch(search, done) {
                try {
                    await this.searchOptions(search);
                } catch (e) {
                    this.options = [];
                } finally {
                    done();
                }
            },
        },
        mounted() {
            this.searchOptions();
        },
    };
</script>
```
</docs>
