<template>
    <div
        class="level"
        :class="{editMode}"
    >
        <!--
            when selecting a level.
            @event input
            @property {Number} value the selected level
        -->
        <div
            v-for="level in 4"
            :key="level"
            class="dot-wrapper"
            @click="$emit('input', level)"
            @mouseenter="updateHoverLevel(level)"
            @mouseleave="updateHoverLevel(undefined)"
        >
            <transition name="fade">
                <div
                    class="dot"
                    :class="{primary: level <= activeLevel}"
                ></div>
            </transition>
        </div>
    </div>
</template>
<script>
export default {
    name: "LevelIndicator",
    props: {
        /** the selected level */
        value: {
            type: Number,
            validator: (value) => (value >= 1 && value <= 4),
        },
        /** enables value changes by clicks, readonly mode otherwise */
        editMode: { type: Boolean },
    },
    data() {
        return {
            hoveredLevel: undefined,
        };
    },
    computed: {
        activeLevel() {
            return this.hoveredLevel || this.value;
        },
    },
    methods: {
        updateHoverLevel(newLevel) {
            this.hoveredLevel = newLevel;
        },
    },
};
</script>
<style lang="scss" scoped>

::v-deep {
    svg {
        circle {
            fill: var(--low-contrast);
        }
        &.primary circle {
            color: var(--primary);
        }
    }
}

.level {
    display: flex;

    .dot {
        border-radius: 50%;
        height: 12px;
        width: 12px;
        margin: 0 0.2rem;
        background-color: var(--low-contrast);

        &.primary {
            background-color: var(--primary);
        }
    }

    &.editMode {
        pointer-events: all;
        .dot-wrapper {
            cursor: pointer;
        }
    }

    &:not(.editMode) {
        pointer-events: none;
    }
}
</style>

<docs>
### Display mode
```vue
<level-indicator :value="2"/>
```
### Edit mode
```vue
<level-indicator :value="1" edit-mode/>
```
</docs>
