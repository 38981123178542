<template>
    <div
        :class="{
            editMode,
            grid: useGridLayout,
            flex: !useGridLayout,
        }"
        class="skill-grid flex-wrap"
    >
        <template v-for="(skill, index) in skills">
            <deletable-wrapper
                v-if="showSkill(skill, index)"
                :key="index"
                :edit-mode="editMode"
                :style="style"
                :delete-icon-size="24"
                class="skill-tile-wrapper"
                :class="{
                    col: useGridLayout,
                    half: useGridLayout,
                }"
                @delete="removedSkillIndexes.push(index)"
            >
                <skill-tile
                    v-model="skills[index]"
                    :edit-mode="editMode && skill === undefined"
                    :editable="editMode"
                    class="skill-tile"
                >
                    <template #editing="{skill, setSkill, onBlur}">
                        <slot
                            name="editing"
                            v-bind="{skill, setSkill, onBlur}"
                        ></slot>
                    </template>
                    <template #viewing="{skill}">
                        <slot
                            name="viewing"
                            v-bind="{skill}"
                        ></slot>
                    </template>
                </skill-tile>
            </deletable-wrapper>
        </template>
        <p
            v-if="!editMode && !hasSkills"
            class="text-medium-contrast"
        >
            {{ placeholder }}
        </p>
        <div
            v-if="editMode"
            :style="style"
            class="skill-tile-wrapper add-button bold"
            @click="addSkill"
        >
            {{ addButtonText }}
        </div>
    </div>
</template>

<script>
import DeletableWrapper from "@web/components/attachments/DeletableFrame";
import SkillTile from "@web/components/profile/SkillTile";
import { cloneDeepClean } from "@/lib/cloneDeepClean";

export default {
    name: "SkillGrid",
    components: {
        DeletableWrapper,
        SkillTile,
    },
    props: {
        /** Displayed when no skill is set. */
        placeholder: { type: String, default: "" },
        /**
         * The selected set of skills.
         * @type {Skill[]}
         * @model
         */
        value: { type: Array, default: () => [] },
        /** The fixed height of each tile within the grid. */
        tileHeight: { type: Number, default: 50 },
        /** Label of the add button text at the end of the grid. */
        addButtonText: { type: String, default: "" },
        /** Tiles are editable when set. */
        editMode: { type: Boolean, default: false },
        /** If set to true, a grid layout with two skills per row will be used */
        useGridLayout: { type: Boolean, default: true },
    },
    data() {
        return {
            skills: cloneDeepClean(this.value),
            removedSkillIndexes: [],
        };
    },
    computed: {
        hasSkills() {
            return this.skills.filter((skill, index) => !this.removedSkillIndexes.includes(index)).length > 0;
        },
        style() {
            return {
                height: this.tileHeight + "px",
            };
        },
    },
    watch: {
        removedSkillIndexes: "emitInput",
        skills: "emitInput",
    },
    methods: {
        showSkill(skill, index) {
            if (this.removedSkillIndexes.includes(index)) return false;
            if (!this.editMode && (!skill || !skill.value)) return false;
            return true;
        },
        emitInput() {
            const skills = this.skills
                .filter((skill) =>
                    skill !== undefined &&
                    skill.value &&
                    skill.proficiency !== undefined)
                .filter((skill, index) => !this.removedSkillIndexes.includes(index));
            /**
             * When any input in the grid changes.
             * @property {Skill[]} skills the set of selected skills.
             */
            this.$emit("input", skills);
        },
        addSkill() {
            this.skills.push(undefined);
        },
    },
};
</script>

<style lang="scss" scoped>
.skill-grid {
    margin: 0;

    .skill-tile-wrapper {
        max-width: calc(50% - 0.5rem);
        margin: 0 $spacing-xsmall $spacing-small 0;

        .skill-tile {
            width: 100%;
        }
    }

    .add-button {
        width: 200px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--medium-contrast);
        border: 1px var(--medium-contrast) dashed;
        border-radius: var(--border-radius);
    }

    &.grid .add-button {
        width: 100%;
    }

    ::v-deep {
        .delete-button-container {
            top: -1rem !important;
            right: -1rem !important;

            .remove-icon {
                width: 24px !important;
                height: 24px !important;
            }

            svg {
                margin: auto;
                width: 18px;
                height: inherit;
            }
        }
    }
}

.skill-grid:not(.editMode) {
    .skill-tile {
        cursor: auto;
    }
}
</style>

<docs>
As tiles:
```vue
<template>
    <skill-grid
        v-model="value"
        :tile-height="75"
        placeholder="No skill selected"
        add-button-text="add Skill"
    >
        <template #editing="{skill, setSkill, onBlur}">
            <input
                :value="skill"
                @input="setSkill"
                @blur="onBlur"
            />
        </template>
        <template #viewing="{skill}">
            <p>{{ skill.value.englishName }}</p>
        </template>
    </skill-grid>
</template>
<script>
    export default {
        data() {
            return {
                value: [
                    { value: { iso2: "de", englishName: "German", nativeName: "Deutsch" }, proficiency: 4 },
                    { value: { iso2: "en", englishName: "English", nativeName: "English" }, proficiency: 3 },
                    { value: { iso2: "ko", englishName: "Korean", nativeName: "한국어" }, proficiency: 1 },
                ],
            };
        },
    };
</script>
```

As grid:
```vue
<template>
    <skill-grid
        v-model="value"
        :tile-height="75"
        placeholder="No skill selected"
        add-button-text="add Skill"
        :use-grid-layout="false"
    >
        <template #editing="{skill, setSkill, onBlur}">
            <input
                :value="skill"
                @input="setSkill"
                @blur="onBlur"
            />
        </template>
        <template #viewing="{skill}">
            <p>{{ skill.value.englishName }}</p>
        </template>
    </skill-grid>
</template>
<script>
    export default {
        data() {
            return {
                value: [
                    { value: { iso2: "de", englishName: "German", nativeName: "Deutsch" }, proficiency: 4 },
                    { value: { iso2: "en", englishName: "English", nativeName: "English" }, proficiency: 3 },
                    { value: { iso2: "ko", englishName: "Korean", nativeName: "한국어" }, proficiency: 1 },
                ],
            };
        },
    };
</script>
```
</docs>
