<template>
    <div
        v-tooltip.bottom="editable && !stateEditMode ? $t('click_to_edit') : ''"
        :class="{'edit-mode': stateEditMode}"
        :tabindex="1"
        class="skill-tile depth-1"
        @blur="onTileBlur"
        @click="startEditing"
        @keyup.right="increaseProficiency"
        @keyup.left="decreaseProficiency"
    >
        <slot
            v-if="editable && stateEditMode"
            name="editing"
            v-bind="{skill: localValue, setSkill, onBlur: forceBlur}"
        ></slot>
        <slot
            v-else
            name="viewing"
            v-bind="{skill: localValue}"
        ></slot>

        <level-indicator
            v-model="localValue.proficiency"
            :edit-mode="stateEditMode"
            class="level-indicator"
        />
    </div>
</template>

<script>
import LevelIndicator from "@/components/profile/LevelIndicator";

export default {
    name: "SkillTile",
    components: {
        LevelIndicator,
    },
    props: {
        /**
         * The skill proficiency and value.
         * @model
         * @type {Skill}
         */
        value: { type: Object, default: () => ({ proficiency: 1, value: undefined }) },
        editMode: Boolean,
        editable: Boolean,
    },
    data() {
        return {
            localValue: this.value,
            stateEditMode: this.editMode,
        };
    },
    watch: {
        localValue: {
            handler(newValue) {
                this.$emit("input", newValue);
            },
            deep: true,
        },
    },
    methods: {
        // https://stackoverflow.com/a/60094794/13320785
        onTileBlur(event) {
            // if the blur was because of outside focus
            // currentTarget is the parent element, relatedTarget is the clicked element
            if (!event.currentTarget.contains(event.relatedTarget)) {
                this.stateEditMode = false;
            }
        },
        forceBlur() {
            this.$el.focus();
        },
        increaseProficiency() {
            if (this.proficiency < 4) {
                this.proficiency++;
            }
        },
        decreaseProficiency() {
            if (this.proficiency > 1) {
                this.proficiency--;
            }
        },
        setSkill(skill) {
            this.localValue = skill;
        },
        startEditing() {
            if (!this.editable) {
                return;
            }
            this.stateEditMode = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.skill-tile {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    border: 1px solid var(--low-contrast);
    border-radius: var(--border-radius);

    &:not(.editMode) {
        cursor: pointer;
    }

    .level-indicator {
        margin-left: .5rem;
    }
}
</style>
