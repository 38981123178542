<template>
    <LoadingWrapper
        :is-loading="isLoadingProfile"
    >
        <SystemMessage
            v-if="error"
            :type="error.type"
        />
        <section
            v-if="!error"
            class="profile container"
        >
            <header-section
                :user="user"
                :editable="!editing"
                :open-in-edit-mode="focusSection === profileSection.header"
                @update:editing="editing = $event"
                @section-updated="onSectionUpdated"
            />
            <div class="main ptop-medium">
                <div class="grid">
                    <div class="col half">
                        <about-me-section
                            :user="user"
                            :editable="!editing"
                            :user-allowed-to-edit="editable"
                            :open-in-edit-mode="focusSection === profileSection.aboutMe"
                            @update:editing="editing = $event"
                            @section-updated="onSectionUpdated"
                        />
                        <info-section
                            :user="user"
                            :editable="!editing"
                            :open-in-edit-mode="focusSection === profileSection.info"
                            @update:editing="editing = $event"
                            @section-updated="onSectionUpdated"
                        />
                        <language-section
                            :user="user"
                            :editable="!editing"
                            :user-allowed-to-edit="editable"
                            :open-in-edit-mode="focusSection === profileSection.language"
                            @update:editing="editing = $event"
                            @section-updated="onSectionUpdated"
                        />
                        <skill-section
                            :user="user"
                            :editable="!editing"
                            :user-allowed-to-edit="editable"
                            :open-in-edit-mode="focusSection === profileSection.skill"
                            @update:editing="editing = $event"
                            @section-updated="onSectionUpdated"
                        />
                    </div>
                    <div class="col half">
                        <contact-section
                            :user="user"
                            :editable="!editing"
                            :open-in-edit-mode="focusSection === profileSection.contact"
                            @update:editing="editing = $event"
                            @section-updated="onSectionUpdated"
                        />
                    </div>
                </div>
            </div>
        </section>

        <ImageCropperModal />
    </LoadingWrapper>
</template>

<script>
import { profileService } from "@web/services";
import { INTRANET } from "@web/store/intranet/getters";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { APPLY_CURRENT_USER_PROFILE_OF_INTRANET } from "@web/store/profile/actions";
import ImageCropperModal from "@/views/intranet/directory/ProfileDetail/ImageCropperModal";
import LanguageSection from "@/views/intranet/directory/ProfileDetail/LanguageSection";
import ContactSection from "@/views/intranet/directory/ContactSection";
import InfoSection from "@/views/intranet/directory/ProfileDetail/InfoSection";
import HeaderSection from "@/views/intranet/directory/ProfileDetail/HeaderSection";
import AboutMeSection from "@/views/intranet/directory/ProfileDetail/AboutMeSection";
import SkillSection from "@/views/intranet/directory/ProfileDetail/SkillSection";
import {
    getProfileProgress,
    ProfileSection,
} from "@/components/profile/profile-progress-helper";
import { wait } from "@/lib/wait";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

const confettiDurationMs = 3000;

/**
 * When adding a profile field also see {@link EditableProfileSection}, {@link userProfileToFormData} and {@link formDataToUserProfile}.
 */
export default {
    components: {
        SkillSection,
        AboutMeSection,
        HeaderSection,
        InfoSection,
        ContactSection,
        LanguageSection,
        ImageCropperModal,
    },
    props: {
        userUid: String,
        focusSection: String,
    },
    data() {
        return {
            user: {},
            editing: false,
            isLoadingProfile: false,
            error: null,
            profileSection: ProfileSection,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        editable() {
            return this.userUid === this.currentUser.uid;
        },
    },
    mounted() {
        this.fetchProfile();
    },
    methods: {
        ...mapActions({
            updateCurrentUserBaseProfile: PROFILE_MODULE_NAME + APPLY_CURRENT_USER_PROFILE_OF_INTRANET,
        }),
        async onSectionUpdated(data) {
            this.user = { ...this.user, ...data };
            // Update currentUser in state so name change is displayed
            if ("displayName" in data) {
                await this.updateCurrentUserBaseProfile();
            }
            const { levelCount, level } = getProfileProgress(this.user);
            const progressInDecimal = levelCount / (Object.keys(ProfileSection).length - 1);
            if (level.id === ProfileSection.completed) {
                this.$notify({
                    group: "app",
                    type: "success",
                    title: this.$t("profile_progress_success_title", [100]),
                });
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_progress_saved, { progress: progressInDecimal });
                this.$confetti.start();
                wait(confettiDurationMs).then(() => this.$confetti.stop());
            } else {
                this.$notify({
                    group: "app",
                    type: "success",
                    title: this.$t("profile_progress_success_title", [Math.floor(progressInDecimal * 100)]),
                    text: this.$t("profile_progress_success_text"),
                });
            }
        },
        async fetchProfile() {
            this.isLoadingProfile = true;
            const intranetUid = this.intranet.uid;
            // load given user or current user
            const userUid = this.userUid || this.currentUser.uid;
            try {
                this.user = await profileService.getFullUserProfile(intranetUid, userUid);
            } catch (e) {
                if (e.response.status === 404) {
                    this.error = {
                        type: "404",
                    };
                    return;
                }
                this.error = {
                    type: "unknown",
                };
            } finally {
                this.isLoadingProfile = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    header {
        .edit-button {
            padding-right: $spacing-medium;
        }
    }
}

.main {
    margin-top: 50px;
    border-top: 1px solid $light-grey;
    min-height: 600px;

    ::v-deep {
        .form-group {
            max-width: 20rem;
        }

        .editable-section {
            margin-bottom: 2rem;

            h3 {
                margin-bottom: 1rem;
            }
        }

        table {
            tr {
                &:first-child {
                    td {
                        padding-top: 0;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 0;
                    }
                }

                td {
                    padding: .75rem .75rem 0 0;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.grid {
    > div > div {
        position: relative;
    }
}
</style>
