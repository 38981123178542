<template>
    <modal
        name="image-cropper-modal"
        custom-width="50%"
        @before-open="init"
        @before-close="reset"
        @modal-confirm="confirmSelection"
    >
        <spinner
            v-if="!image"
            class="spinner"
        ></spinner>
        <vue-cropper
            v-else
            ref="cropper"
            :class="{rounded: cropperRounded}"
            :src="image"
            :container-style="{
                'max-height': '60vh',
            }"
            :aspect-ratio="cropperAspectRatio"
            :zoomable="false"
            :auto-crop-area="1"
            :view-mode="1"
        >
        </vue-cropper>
    </modal>
</template>

<script>
import VueCropper from "vue-cropperjs/VueCropper";
import "cropperjs/dist/cropper.css";
import Spinner from "@/components/Spinner";
import Modal from "@/components/modals/Modal";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { compressImage } from "@/lib/image-compression";

export default {
    name: "ImageCropperModal",
    components: {
        Modal,
        VueCropper,
        Spinner,
    },
    data: () => ({
        image: null,
        cropHandler: null,
        cropperAspectRatio: null,
        cropperRounded: true,
    }),
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
    },
    methods: {
        async init(event) {
            this.cropperAspectRatio = event.params.cropperAspectRatio;
            this.cropperRounded = event.params.cropperRounded;
            this.cropHandler = event.params.cropHandler;
            this.image = await event.params.image;
        },
        reset() {
            this.cropperAspectRatio = null;
            this.cropperRounded = null;
            this.image = null;
            this.cropHandler = null;
        },
        async confirmSelection() {
            if (!this.image || !this.$refs.cropper || !this.cropHandler) {
                return;
            }
            const cropHandler = this.cropHandler;
            // max image size: pixel count x channel count
            // 1600 x 1600 x 3 = 7.680.000 Byte
            // 7.680.000 / (1024 * 1024) = ~7.32MB
            this.$refs.cropper.getCroppedCanvas({
                maxWidth: 1600,
                maxHeight: 1600,
                fillColor: this.intranet.colors.primaryColor,
            }).toBlob(async(blob) => await cropHandler(await compressImage(blob)));
        },
    },
};
</script>

<style lang="scss" scoped>
.spinner {
    height: 60vh;
}

.rounded {
    ::v-deep {
        .cropper-crop-box, .cropper-view-box {
            border-radius: 50%;
        }

        .cropper-view-box {
            box-shadow: 0 0 0 1px #39f;
            outline: 0;
        }
    }
}
</style>
