<template>
    <section
        v-global-page-title="user.displayName"
        class="profile-header center-container"
    >
        <ProfileCoverAndAvatar
            :user="user"
            @section-updated="$emit('section-updated', $event)"
        >
            <template #displayName>
                <slot name="displayName"></slot>
            </template>
        </ProfileCoverAndAvatar>
        <div class="grid transition-all top-intro">
            <div :class="colClass">
                <div class="top-intro-field-headline">
                    {{ $t("user_profile_head_job_title") }}
                </div>
                <slot name="position">
                    <h3 class="top-intro-field-headline ellipsis-multiline">
                        {{ user.jobTitle || "-" }}
                    </h3>
                </slot>
            </div>
            <div :class="colClass">
                <div class="top-intro-field-headline">
                    {{ $t("user_profile_head_team") }}
                </div>
                <slot name="team">
                    <h3 class="top-intro-field-headline ellipsis-multiline hover-underline">
                        <team-link
                            v-if="user.team && user.team.name"
                            v-bind="user.team"
                        >
                            {{ user.team.name }}
                        </team-link>
                        <span v-else>-</span>
                    </h3>
                </slot>
            </div>
            <div :class="colClass">
                <div class="top-intro-field-headline">
                    {{ $t("user_profile_head_location") }}
                </div>
                <slot name="location">
                    <h3 class="top-intro-field-headline ellipsis-multiline">
                        {{ locationName || "-" }}
                    </h3>
                    <div
                        v-if="locationAddress"
                        class="address ellipsis-multiline"
                    >
                        {{ locationAddress }}
                    </div>
                </slot>
            </div>
        </div>
    </section>
</template>
<script>
import ProfileCoverAndAvatar from "@/views/intranet/directory/ProfileDetail/ProfileCoverAndAvatar";
import TeamLink from "@/components/team/TeamLink";

export default {
    name: "ProfileHead",
    components: {
        ProfileCoverAndAvatar,
        TeamLink,
    },
    props: {
        user: Object,
    },
    data() {
        return {
            colClass: "col third ptop-small transition-all top-intro-column",
        };
    },
    computed: {
        locationName() {
            return this.user.location && this.user.location.name;
        },
        locationAddress() {
            return this.user.location && this.user.location.place && this.user.location.place.formatted_address;
        },
    },
};
</script>
<style lang="scss" scoped>
.profile-header {
    flex-direction: column;
    position: relative;

    .top-intro {
        width: 100%;
        min-height: 80px;
        padding-left: 240px;
        padding-right: 200px;

        .top-intro-column {
            margin: 0;
            flex: 0 0 auto;

            .top-intro-field-headline {
                margin: 0 0 0.25rem 0;
                overflow: hidden;
                word-break: break-word;
            }

            .address {
                color: var(--high-contrast);
            }
        }
    }

    a {
        text-decoration: none;
    }

    @media screen and (max-width: $breakpoint-profile-large) {
        ::v-deep {
            .hey-five-button .button {
                width: 3rem;
                height: 3rem;
                border-radius: 1.5rem;
                padding: .8rem .9rem;

                .inner {
                    justify-content: center;
                    margin-left: .05rem;
                }
            }

            .hey-five-button-text {
                width: 0;
                height: 0;
                margin: 0;
                display: block;
                overflow: hidden;
            }
        }

        .top-intro {
            padding-left: 0;
        }
    }
}
</style>
