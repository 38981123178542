<template>
    <vue-tel-input
        :style-classes="['input-phone', !isFieldValid ? 'error' : '']"
        :value="phoneNumber"
        mode="international"
        valid-characters-only
        @input="setPhoneNumber($event)"
        @validate="isNumberValid = $event.valid"
    />
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
    name: "PhoneInput",
    components: {
        VueTelInput,
    },
    props: {
        /**
         * The phone object.
         * @model
         * @type {Phone}
         */
        value: { type: Object, default: () => ({ number: "" }) },
    },
    data() {
        return ({
            isFieldValid: true,
            isNumberValid: true,
        });
    },
    computed: {
        phoneNumber() {
            return this.value.number;
        },
    },
    methods: {
        setPhoneNumber(number) {
            this.isFieldValid = this.phoneNumber === "" || this.isNumberValid;
            /**
             * When input value changed.
             * @property {object} phone the phone object with the number field.
             */
            this.$emit("input", { number });
            /**
             * When validation is fired.
             * @property {boolean} isFieldValid
             */
            this.$emit("validate", this.isFieldValid);
        },
    },
};
</script>
<style lang="scss">
// basic styling in `_forms.scss`

.input-phone {
    padding: 0 !important;
    border-radius: 5px;
    position: relative;

    &:focus-within {
        box-shadow: none !important;
    }

    .vti__dropdown {
        padding: 0.5rem 0.75rem;
        background-color: var(--lowest-contrast, #{$light-grey});
        border-radius: 4px 0 0 4px;
        position: initial;

        &:hover {
            background-color: var(--low-contrast, #{$light-grey});
        }
    }

    input[type="tel"] {
        padding: 0.5rem 0.75rem;
        border-radius: 0 4px 4px 0;

        &::placeholder {
            color: var(--medium-contrast, #{$mid-grey});
        }
    }

    .vti__dropdown-list {
        box-shadow: $depth-1;
        border: 1px solid var(--low-contrast, #{$light-grey});
        border-radius: 5px;
        width: calc(100% + 2px);

        &.below {
            top: calc(100% + 5px);
        }

        .vti__dropdown-item {
            border-bottom: 1px solid var(--lowest-contrast, #{$light-grey});
            padding: .5rem 15px;

            &.highlighted {
                background: var(--lowest-contrast, #{$light-grey});
            }

            strong {
                font-weight: initial;
                margin-left: 10px;
            }
        }
    }
}
</style>

<docs>
```vue
<template>
    <div>
        <phone-input v-model="value"/>
        <br/>
        {{ value }}
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: {},
            };
        },
    };
</script>
```
</docs>
