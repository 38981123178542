<template>
    <editable-profile-section
        v-if="userAllowedToEdit || hasLanguageSkills"
        :fields="['languages']"
        :title="$t('i_speak')"
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <language-skill-grid
                :value="user.languages"
            />
        </template>
        <template #editing="{formData}">
            <language-skill-grid
                v-model="formData.languages"
                :available-languages="iso2Languages"
                edit-mode
            />
        </template>
    </editable-profile-section>
</template>

<script>
import LanguageSkillGrid from "@/views/intranet/directory/ProfileDetail/LanguageSkillGrid";
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";
import { profileAuxiliaryService } from "@/services";

export default {
    name: "LanguageSection",
    components: {
        EditableProfileSection,
        LanguageSkillGrid,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        userAllowedToEdit: Boolean,
        openInEditMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iso2Languages: [],
        };
    },
    computed: {
        hasLanguageSkills() {
            if (this.user.languages) return this.user.languages.length > 0;
            return false;
        },
    },
    mounted() {
        if (this.editable) this.fetchLanguages();
    },
    methods: {
        async fetchLanguages() {
            try {
                /** @type {Iso2Language[]} */
                const iso2Languages = await profileAuxiliaryService.getIsoLanguages();
                iso2Languages.sort((a, b) => a.englishName.localeCompare(b.englishName));
                this.iso2Languages = iso2Languages;
            } catch (e) {
                this.$notify({
                    type: "error",
                    title: "unknown_error",
                    body: "unknown_error_text",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
