var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"skill-grid flex-wrap",class:{
        editMode: _vm.editMode,
        grid: _vm.useGridLayout,
        flex: !_vm.useGridLayout,
    }},[_vm._l((_vm.skills),function(skill,index){return [(_vm.showSkill(skill, index))?_c('deletable-wrapper',{key:index,staticClass:"skill-tile-wrapper",class:{
                col: _vm.useGridLayout,
                half: _vm.useGridLayout,
            },style:(_vm.style),attrs:{"edit-mode":_vm.editMode,"delete-icon-size":24},on:{"delete":function($event){return _vm.removedSkillIndexes.push(index)}}},[_c('skill-tile',{staticClass:"skill-tile",attrs:{"edit-mode":_vm.editMode && skill === undefined,"editable":_vm.editMode},scopedSlots:_vm._u([{key:"editing",fn:function(ref){
            var skill = ref.skill;
            var setSkill = ref.setSkill;
            var onBlur = ref.onBlur;
return [_vm._t("editing",null,null,{skill: skill, setSkill: setSkill, onBlur: onBlur})]}},{key:"viewing",fn:function(ref){
            var skill = ref.skill;
return [_vm._t("viewing",null,null,{skill: skill})]}}],null,true),model:{value:(_vm.skills[index]),callback:function ($$v) {_vm.$set(_vm.skills, index, $$v)},expression:"skills[index]"}})],1):_vm._e()]}),(!_vm.editMode && !_vm.hasSkills)?_c('p',{staticClass:"text-medium-contrast"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"skill-tile-wrapper add-button bold",style:(_vm.style),on:{"click":_vm.addSkill}},[_vm._v(" "+_vm._s(_vm.addButtonText)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }