<template>
    <editable-profile-section
        :title="$t('contact')"
        :fields="['phone']"
        :user="user"
        :editable="editable"
        :open-in-edit-mode="openInEditMode"
        @update:editing="$emit('update:editing', $event)"
        @section-updated="$emit('section-updated', $event)"
    >
        <template #viewing>
            <table>
                <tr>
                    <td class="text-nowrap">
                        {{ $t("email") }}
                    </td>
                    <td>
                        <a
                            :href="`mailto:${user.email}`"
                            class="link"
                            rel="noopener"
                            @click="logOpenMail"
                        >
                            <strong>
                                {{ user.email }}
                            </strong>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="text-nowrap">
                        {{ $t("phone_number") }}
                    </td>
                    <td>
                        <a
                            v-if="user.phone"
                            :href="`tel:${user.phone.number}`"
                            class="link"
                            rel="noopener"
                            @click="logOpenTel"
                        >
                            <strong>
                                {{ user.phone.number }}
                            </strong>
                        </a>
                        <span v-else>-</span>
                    </td>
                </tr>
            </table>
        </template>
        <template #editing="{formData, isSaving, setValidationState}">
            <div class="form-group">
                <label for="email">{{ $t("email") }}</label>
                <input
                    id="email"
                    type="text"
                    :value="user.email"
                    disabled
                />
            </div>
            <div class="form-group">
                <label for="phoneNumber">{{ $t("phone_number") }}</label>
                <phone-input
                    id="phoneNumber"
                    v-model="formData.phone"
                    @validate="setValidationState($event)"
                />
            </div>
        </template>
    </editable-profile-section>
</template>

<script>
import EditableProfileSection from "@/views/intranet/directory/ProfileDetail/EditableProfileSection";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";
import PhoneInput from "@/components/profile/PhoneInput";

export default {
    name: "ContactSection",
    components: {
        EditableProfileSection,
        PhoneInput,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: Boolean,
        openInEditMode: Boolean,
    },
    methods: {
        logOpenMail() {
            analytics.log(getGlobalConfiguration().analytics_event_name_profile_open_mail);
        },
        logOpenTel() {
            analytics.log(getGlobalConfiguration().analytics_event_name_profile_open_tel);
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
