<template>
    <autocomplete-input
        :placeholder="placeholder"
        :options="options"
        :value="value"
        :max-length="maxLength"
        :disabled="disabled"
        @input="$emit('input', $event)"
    />
</template>

<script>
import { userService } from "@web/services";
import { mapGetters } from "vuex";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { getGlobalConfiguration } from "@web/global-config";
import AutocompleteInput from "@web/components/forms/AutocompleteInput";

export default {
    name: "JobTitleInput",
    components: {
        AutocompleteInput,
    },
    props: {
        /** @model */
        value: String,
        placeholder: String,
        disabled: Boolean,
        maxAutocompleteEntries: { type: Number, default: 20 },
    },
    data() {
        return {
            options: [],
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET_UID]),
        maxLength() {
            return getGlobalConfiguration().input_validation_user_profile_job_title_max_length;
        },
    },
    mounted() {
        this.searchForValues();
    },
    methods: {
        async searchForValues(search) {
            const { facetHits } = await userService.search.searchUserProfileFieldValues({
                intranetUid: this.intranetUid,
                field: "jobTitle",
                query: search,
                pageNumber: 0,
                pageSize: this.maxAutocompleteEntries,
            });
            this.options = facetHits.map(({ value }) => value);
        },
        async onSearch(search, done) {
            try {
                await this.searchForValues(search);
            } catch (e) {
                this.options = [];
            } finally {
                done();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
